import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paymentProvider',
    standalone: false
})
export class PaymentProviderPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		if (args && args[0] && args[0]['type'] && args[0]['type'] === 'hidden') {
			switch (value) {
				case 'paddle:billing':
					return 'Paddle Billing';
				case 'paddle:classic':
					return 'Paddle Classic';
				default:
					return '-';
			}
		} else {
			switch (value) {
				case 'paddle:billing':
					return 'Paddle Billing';
				case 'paddle:classic':
					return 'Paddle Classic';
				default:
					return 'Manuelle Rechnung';
			}
		}
	}
}
