import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sapConnectionType',
    standalone: false
})
export class SapConnectionTypePipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'customer':
				return 'Kunde';
			case 'partner':
				return 'Partner';
			case 'freelancer':
				return 'Freelancer';
			case 'university':
				return 'Universität / Hochschule';
			default:
				return 'Andere';
		}
	}
}
