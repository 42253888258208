import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lastLogin',
    standalone: false
})
export class LastLoginPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): unknown {
		value = value.replace('weeks', 'Wochen');
		value = value.replace('week', 'Woche');
		value = value.replace('ago', '');
		value = value.replace('months', 'Monate');
		value = value.replace('month', 'Monat');
		value = value.replace('years', 'Jahre');
		value = value.replace('year', 'Jahr');
		return value;
	}
}
