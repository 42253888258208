import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'owner',
    standalone: false
})
export class OwnerPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'F':
				return 'Fremdes Produkt';
			default:
				return 'Eigenes Produkt';
		}
	}
}
