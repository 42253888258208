import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'showOrHideProduct',
    pure: false,
    standalone: false
})
export class ShowOrHideProductPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (value.type === 'video') {
			if (args[0][1].isChecked) {
				return true;
			}
		} else if (value.type === 'book') {
			if (args[0][0].isChecked) {
				return true;
			}
		} else if (value.type === 'blended-learning') {
			if (args[0][2].isChecked) {
				return true;
			}
		} else if (value.type === 'embed') {
			if (args[0][3].isChecked) {
				return true;
			}
		} else if (value.type === 'demo') {
			if (args[0][4].isChecked) {
				return true;
			}
		}
	}
}
