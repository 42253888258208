import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'eventType',
    standalone: false
})
export class EventTypePipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'updated':
				return 'Datensatz bearbeitet';
			case 'created':
				return 'Datensatz erstellt';
			case 'deleted':
				return 'Datensatz gelöscht';
			default:
				return 'Datensatz bearbeitet';
		}
	}
}
