import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchList',
    standalone: false
})
export class SearchListPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items) {
			return [];
		}
		if (!searchText) {
			return items;
		}
		searchText = searchText.toLocaleLowerCase();

		// @ts-ignore
		return items.filter((v: any) => {
			if (v.title && searchText) {
				return v.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
			}
		});
	}
}
