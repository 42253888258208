import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../interfaces/country';

@Pipe({
    name: 'country',
    standalone: false
})
export class CountryPipe implements PipeTransform {
	transform(value: unknown, ...args: Array<Array<Country>>): unknown {
		const el = args[0].find((x) => x.code === value);

		if (!el) {
			return 'Unbekannt';
		}

		return el.name;
	}
}
