import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cutter',
    standalone: false
})
export class CutterPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): unknown {
		const long = value;
		let short = value;
		if (value.length > 30) {
			short = value.substr(0, 30) + '\u2026';
		}
		return `<span title="${long}">${short}</span>`;
	}
}
