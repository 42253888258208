import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'status',
    standalone: false
})
export class StatusPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'inactive':
			case 'deleted':
				return 'Inaktiv';
			case 'active':
				return 'Aktiv';
			case 'outdated':
				return 'Veraltet';
			case 'in_progress':
				return 'Bearbeitung';
			case 'cancelled':
			case 'canceled':
				return 'Abgebrochen';
			case 'preview':
				return 'Vorschau';
			case 'draft':
				return 'Entwurf';
			case 'license_expired':
				return 'Lizenz abgelaufen';
			case 'open':
				return 'Offen';
			case 'pending':
				return 'Ausstehend';
			case 'payed':
				return 'Bezahlt';
			case 'completed':
				return 'Abgeschlossen';
			case 'refunded':
				return 'Erstattet';
			case 'sent':
				return 'Versendet';
			case 'pre_sent':
				return 'Sendend';
			default:
				return 'Veröffentlicht';
		}
	}
}
