import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import filesize from 'filesize';
class FileSizePipe {
  static transformOne(value, options) {
    return filesize(value, options);
  }
  transform(value, options) {
    if (Array.isArray(value)) {
      return value.map(val => FileSizePipe.transformOne(val, options));
    }
    return FileSizePipe.transformOne(value, options);
  }
}
FileSizePipe.ɵfac = function FileSizePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FileSizePipe)();
};
FileSizePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "filesize",
  type: FileSizePipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSizePipe, [{
    type: Pipe,
    args: [{
      name: 'filesize'
    }]
  }], null, null);
})();
class NgxFilesizeModule {}
NgxFilesizeModule.ɵfac = function NgxFilesizeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxFilesizeModule)();
};
NgxFilesizeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxFilesizeModule
});
NgxFilesizeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxFilesizeModule, [{
    type: NgModule,
    args: [{
      declarations: [FileSizePipe],
      exports: [FileSizePipe]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-filesize
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FileSizePipe, NgxFilesizeModule };
