import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from '../../../environments/environment';
import { SessionQuery } from '../session/state';
import { Router } from '@angular/router';
import { PusherResponse } from '../interfaces/pusher';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class PusherService {
	public pusher: any;
	public channel: any;

	private apiUrl = environment.API_URL;

	constructor(
		private sessionQuery: SessionQuery,
		private snackBar: MatSnackBar,
		private router: Router
	) {}

	/*
	 * Get pusher object
	 */
	getPusher(): any {
		console.log('init p');
		const token = this.sessionQuery.getToken();
		// @ts-ignore
		this.pusher = new Pusher(environment.pusher, {
			// @ts-ignore
			appId: environment.pusherAppId,
			wsHost: 'soketi.services.espresso-tutorials.com',
			wssPort: 6001,
			forceTLS: true,
			encrypted: true,
			enabledTransports: ['ws', 'wss'],
			cluster: environment.pusherCluster,
			channelAuthorization: {
				endpoint: `${this.apiUrl}auth/login/broadcast`,
				transport: 'ajax',
				params: {},
				headers: {
					'Authorization': `Bearer ${token}`,
				},
				customHandler: null,
			},
		});
		return this.pusher;
	}

	/**
	 * Listen to channel
	 * @param {string} channelName Channel name
	 * @param {any} event Pusher event
	 * @param {any} callback Callback function
	 */
	listen(channelName: string, event: any, callback: any) {
		console.log('listen', channelName, event);
		this.channel = this.pusher.subscribe(channelName);

		this.channel.bind(event, (res) => {
			callback(res);
		});

		this.channel.bind('pusher:subscription_error', (err) => {
			console.log('pusher error', err);
		});

		/*
    this.channel.bind_global((members) => {
      console.log('bind_global', members);
    });

    this.channel.bind("pusher:subscription_succeeded", (members) => {
      console.log('pusher members', members);
    });
    */
	}

	/**
	 * Unsubscribe from channel
	 * @param {string} channelName Channel name
	 */
	unsubscribe(channelName: string): any {
		if (this.pusher) {
			this.pusher.unsubscribe(channelName);
		}
	}

	/**
	 * Hande pusher response
	 * @param {PusherResponse} res Response from api
	 */
	handlePusherResponse(res: PusherResponse): void {
		console.log('handlePusherResponse', res);
		if (res.type === 'IMPORT_SUCCESSFULLY') {
			this.openSnackBar(`Import: ${res.data.template} mit ${res.data.total} Datensätzen erfolgreich.`);
		} else if (res.type === 'CREDIT_LINE_GENERATION_SUCCESSFULLY') {
			this.openSnackBar(`${res.data.total} Abrechnungsdaten erstellt.`);
		} else if (res.type === 'CREDIT_GENERATION_SUCCESSFULLY') {
			this.openSnackBar(`${res.data.total} Belege erstellt.`);
		} else if (res.type === 'CONFERENCE_ATTENDEE_IMPORT_SUCCESSFULLY') {
			this.openSnackBar(`Konferenzteilnehmer erfolgreich hinzugefügt.`);
		} else if (res.type === 'LOGISTIC_COST_CALCULATION_SUCCESSFULLY') {
			this.openSnackBar(
				`${res.data.costs} Logistikkosten wurden in der Periode ${res.data.period} auf ${res.data.total} verkaufte Printinhalte je ${res.data.costsPerItem} aufgeteilt`
			);
		} else if (res.type === 'ORDER_UPDATED') {
			this.openSnackBar(`Bestellung aktualisiert: ${res.data.number}`);
		}
	}

	// Display Snackbar
	openSnackBar(message: string): any {
		this.snackBar.open(message, 'OK', {
			duration: 10000,
			horizontalPosition: 'right',
			verticalPosition: 'top',
		});
	}
}
