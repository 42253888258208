import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'billingFielValueTitle',
    standalone: false
})
export class BillingFielValueTitlePipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		if (value === 'Date') {
			return 'Datum';
		} else {
			return null;
		}
	}
}
