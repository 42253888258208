import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'billingFielValuePlaceholder',
    standalone: false
})
export class BillingFielValuePlaceholderPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		if (value === 'Date') {
			return 'Datum eintragen';
		} else {
			return null;
		}
	}
}
