import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SessionQuery } from '../session/state';

@Directive({
    selector: '[appAccessControl]',
    standalone: false
})
export class AccessControlDirective implements OnInit {
	@Input() accessRole: Array<string>;
	@Input() accessRoleClass?: string | undefined;

	constructor(
		private elementRef: ElementRef,
		private sessionQuery: SessionQuery
	) {}

	ngOnInit(): void {
		this.elementRef.nativeElement.style.display = 'none';
		this.checkAccess();
	}

	checkAccess(): void {
		const roles = this.sessionQuery.hasRoles();

		let hasRole = false;
		if (roles && roles.length > 0) {
			/*
      hasRole = roles.every((el) => {
        return this.accessRole.indexOf(el) !== -1;
      });
      */
			hasRole = this.accessRole.some((item) => roles.includes(item));
		}

		if (this.accessRoleClass) {
			this.elementRef.nativeElement.style.display = hasRole ? this.accessRoleClass : 'none';
		} else {
			this.elementRef.nativeElement.style.display = hasRole ? 'block' : 'none';
		}
	}
}
