import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-line-items-dialog',
    templateUrl: './delete-line-items-dialog.component.html',
    styleUrls: ['./delete-line-items-dialog.component.css'],
    standalone: false
})
export class DeleteLineItemsDialogComponent implements OnInit {
	public origin: string;
	public date: string;

	constructor(
		public dialogRef: MatDialogRef<DeleteLineItemsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {}

	ngOnInit(): void {}

	onDelete(date: string, origin: string): void {
		if (!date || !origin) {
			return;
		}
		this.dialogRef.close({ date, origin });
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
