import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'modification',
    standalone: false
})
export class ModificationPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		if (value && typeof value === 'object') {
			return Object.keys(value).map((key) => [key, value[key]]);
		} else {
			return null;
		}
	}
}
