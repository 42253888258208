import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'language',
    standalone: false
})
export class LanguagePipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'de':
				return 'Deutsch';
			case 'fr':
				return 'Französisch';
			case 'pt':
				return 'Portugiesisch';
			case 'es':
				return 'Spanisch';
			case 'ja':
				return 'Japanisch';
			default:
				return 'Englisch';
		}
	}
}
