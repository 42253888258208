import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-order-dialog',
    templateUrl: './order-dialog.component.html',
    styleUrls: ['./order-dialog.component.css'],
    standalone: false
})
export class OrderDialogComponent implements OnInit {
	public id: number;
	public type: string = 'paid';
	public items: Array<any> = [];
	public refundItems: Array<any> = [];
	public reason: string = 'requested_by_customer';

	constructor(
		public dialogRef: MatDialogRef<OrderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {
		this.id = data.id;
		this.type = data.type;
		this.items = data.items ? data.items : [];
	}

	ngOnInit(): void {}

	onSelectItem(id: any): void {
		const checked = document.getElementById(`checked-${id}`);
		const quantity = document.getElementById(`quantity-${id}`);

		// @ts-ignore
		console.log(checked.checked, parseFloat(quantity.value));

		// @ts-ignore
		if (checked.checked && parseFloat(quantity.value) > 0) {
			console.log('checked');
			// @ts-ignore
			const i = this.refundItems.findIndex((e) => e.id === id);
			if (i > -1) {
				// @ts-ignore
				this.refundItems[i] = { id, quantity: quantity.value };
			} else {
				// @ts-ignore
				this.refundItems.push({ id, quantity: quantity.value });
			}
		}
	}

	onDelete(id: number): void {
		if (this.type === 'paid') {
			this.dialogRef.close({ id });
		}
		if (this.type === 'shipped') {
			const trackingNumber = document.getElementById('trackingNumber');
			// @ts-ignore
			this.dialogRef.close({ id, trackingNumber: trackingNumber ? trackingNumber.value : null });
		}
		if (this.type === 'refund') {
			// @ts-ignore
			this.reason = document.getElementById('refund-reason').value;
			this.dialogRef.close({ id, reason: this.reason, refundItems: this.refundItems });
		}
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
