import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appDragScroll]',
    standalone: false
})
export class DragScrollDirective implements OnInit {
	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		this.dragAndScroll();
	}

	dragAndScroll(): void {
		if (!this.elementRef) {
			return;
		}

		const slider = this.elementRef.nativeElement;
		let isDown = false;
		let startX: number;
		let scrollLeft: number;

		slider.addEventListener('mousedown', (e: { pageX: number }) => {
			isDown = true;
			slider.classList.add('active');
			startX = e.pageX - slider.offsetLeft;
			scrollLeft = slider.scrollLeft;
		});

		slider.addEventListener('mouseleave', () => {
			isDown = false;
			slider.classList.remove('active');
		});

		slider.addEventListener('mouseup', () => {
			isDown = false;
			slider.classList.remove('active');
		});

		slider.addEventListener('mousemove', (e: any) => {
			if (!isDown) {
				return;
			}
			e.preventDefault();

			const x = e.pageX - slider.offsetLeft;
			const walk = (x - startX) * 2;

			slider.scrollLeft = scrollLeft - walk;
		});
	}
}
